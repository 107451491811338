window.requestAnimFrame = (function(){
    return  window.requestAnimationFrame       ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame    ||
        function( callback ){
            window.setTimeout(callback, 1000 / 60);
        };
})();

function menuControl(menu, links) {

    var scrollPos = window.scrollY;
    var unused = true;

    for(var i = 0; i < links.length; i++) {

        var currLink = links[i];
        var elemPrev = i == 0 ? null : document.querySelector(links[i-1].getAttribute('href'));
        var refElement = document.querySelector(currLink.getAttribute('href'));
        var elemNext = (i == links.length - 1) ? null : document.querySelector(links[i+1].getAttribute('href'));
        var currentPrev = elemPrev == null ? true : (scrollPos > elemPrev.offsetTop + elemPrev.clientHeight);
        var currentNext = elemNext == null ? true : (scrollPos < elemNext.offsetTop);

        //if(refElement.offsetTop <= scrollPos && refElement.offsetTop + refElement.clientHeight > scrollPos) {
        if(currentPrev && currentNext && unused) {
            currLink.classList.add('_cs128_nav_active');
            currLink.classList.remove('_cs128_nav_inactive');
            unused = false;
        } else {
            currLink.classList.add('_cs128_nav_inactive');
            currLink.classList.remove('_cs128_nav_active');
        }
    }
}

window.scrollSpy = function scrollSpy(menu) {
    var links = $('#scroll-nav').find("ul").find("li").map((e, i) => { if(i.children[0].classList.contains("_cs128_nav_link")) return i.children[0] });
    var throttled = _.throttle(function() { menuControl(menu, links); }, 100);
    document.addEventListener('scroll', throttled);
    menuControl(menu, links);
}